<template>
  <main class="max-w-5xl mx-auto flex flex-wrap flex-col justify-between py-20">
    <div v-if="inUpsell">
      <div class="text-xl font-bold text-gray-brand-900 tracking-tighter mx-4 md:mx-2 mb-4">
        <SecondaryButton @click="$emit('showFullView')" class="mb-4" :size="'md'">
          <template #left-icon>
            <svg
              class="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
            </svg>
          </template>
          Show all products
        </SecondaryButton>
        <div>Complete your cart</div>
      </div>
      <div class="min-h-screen mt-2 md:mx-0 flex justify-start content-start flex-wrap flex-grow">
        <template v-for="categoryProductStorePair in upsells" :key="categoryProductStorePair.id">
          <CategoryProductStorePairContainer
            :category-product-store-pair="categoryProductStorePair"
            :in-cart="inCart"
            :in-upsell="true"
          />
        </template>
      </div>
    </div>

    <template v-else>
      <!-- SHOW STORE HEADER IMAGE -->
      <div v-if="showHeaderImage" class="-mt-6 mx-0 md:mt-4 md:mx-4 mb-2">
        <div
          class="rounded-none md:rounded-lg overflow-hidden w-auto h-32 sm:h-56 md:h-72 shadow-solid-xs shadow-gray-brand-200"
        >
          <img loading="lazy" class="object-cover w-full h-full" :src="headerImageURL" alt="" />
        </div>
      </div>

      <InfoBanner :bottle="bottle" class="" />

      <!-- package options and other options -->
      <PackageSelection :bottle="bottle" :store="store" class="px-4 md:px-2" options-css="sm:grid-cols-3 sm:gap-x-4" />

      <DaySplitSelection />

      <!-- SHOW CART HEADER IF IN CART VIEW -->
      <div v-if="cartView" class="mt-4 mb-4 mx-4 md:mx-2">
        <h2 class="text-2xl font-bold text-gray-brand-900 tracking-tighter w-full">Your cart</h2>
        <SecondaryButton @click="$emit('showFullView')" class="mt-2" :size="'md'">
          <template #left-icon>
            <svg
              class="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
            </svg>
          </template>
          Show all products
        </SecondaryButton>
      </div>

      <!-- ONLY PRODUCTS IN THE CART -->
      <div v-if="cartView" class="min-h-screen mt-2 md:mx-0 flex justify-start content-start flex-wrap flex-grow">
        <NoLongerAvailableCategory :in-cart="inCart" />
        <template v-for="categoryProductStorePair in categoryProductStorePairs" :key="categoryProductStorePair.id">
          <CategoryProductStorePairContainer
            :category-product-store-pair="categoryProductStorePair"
            :in-cart="inCart"
            :in-upsell="false"
          />
        </template>
        <div
          v-if="cartIsEmpty"
          class="mx-4 md:mx-2 w-full bg-gray-brand-200 border border-gray-brand-400 rounded-lg px-4 py-2"
        >
          <h3 class="text-md text-gray-brand-700 w-full">There's nothing in your cart yet.</h3>
        </div>
      </div>

      <!-- ALL CATEGORIES/PRODUCTS -->
      <div v-if="fullView" class="min-h-screen">
        <NoLongerAvailableCategory :in-cart="inCart" />
        <NoCategoryCategory :in-cart="inCart" :category-product-store-pairs="categoryProductStorePairsWithNoCategory" />
        <section v-for="category in categories" :key="category?.attributes.id" class="flex-grow">
          <Category :category="category" :in-cart="inCart" :offer-collapse-functionality="categories.length > 1" />
        </section>
      </div>
    </template>
  </main>
</template>

<script>
import Category from '@/components/Merchant/Category.vue'
import NoLongerAvailableCategory from '@/components/Merchant/NoLongerAvailableCategory.vue'
import NoCategoryCategory from '@/components/Merchant/NoCategoryCategory.vue'
import CategoryProductStorePairContainer from '@/components/Merchant/CategoryProductStorePairContainer.vue'
import BottleCycleBanner from '@/components/Checkout/BottleCycleBanner'
import InfoBanner from '@/components/Checkout/InfoBanner.vue'
import PackageSelection from '@/components/Checkout/PackageSelection.vue'
import DaySplitSelection from '@/components/Checkout/DaySplitSelection'
import SecondaryButton from '../Molecules/SecondaryButton.vue'

export default {
  components: {
    Category,
    NoLongerAvailableCategory,
    NoCategoryCategory,
    CategoryProductStorePairContainer,
    BottleCycleBanner,
    InfoBanner,
    PackageSelection,
    DaySplitSelection,
    SecondaryButton
  },

  props: {
    inCart: {
      type: Boolean
    },
    inUpsell: {
      type: Boolean
    }
  },
  computed: {
    bottle() {
      return this.$store.getters.currentBottle
    },
    categories() {
      return this.$store.getters.categories
    },
    cartView() {
      return this.inCart
    },
    cartIsEmpty() {
      return this.$store.getters.cartSize == 0
    },
    fullView() {
      return !this.inCart
    },
    categoryProductStorePairs() {
      return this.$store.getters.categoryProductStorePairs(
        this.bottle?.relationships?.category_product_store_pairs?.data
      )
    },
    categoryProductStorePairsWithNoCategory() {
      return this.categoryProductStorePairs?.filter((categoryProductStorePair) => {
        return (
          !categoryProductStorePair?.relationships?.category?.data &&
          this.$store.getters.submittableCartItems(categoryProductStorePair?.attributes?.id)?.length > 0
        )
      })
    },
    showHeaderImage() {
      return this.fullView && this.headerImageURL
    },
    headerImageURL() {
      return this.attachments?.[0]?.attributes?.high_res_url
        ? this.attachments?.[0]?.attributes?.high_res_url
        : this.attachments?.[0]?.attributes?.secure_url
    },
    store() {
      return this.$store.getters.store(this.bottle?.relationships?.store?.data)
    },
    attachments() {
      return this.$store.getters.attachmentsFromRelationships(this.store?.relationships?.attachments?.data)
    }
  },

  async mounted() {
    this.upsells = (await this.$store.dispatch('getUpsells')).data
  },
  data() {
    return { upsells: [] }
  },

  methods: {
    isMultibottleFunctionality() {
      const currentBottle = this.$store.getters.currentBottle
      if (currentBottle) {
        return currentBottle.attributes.allow_multiple_days
      } else {
        return false
      }
    },
    formatTimeOrderingOpens(timeString) {
      const date = new Date(timeString)

      // Convert the UTC time to EDT (New York)
      const dateString = date.toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
      })

      // Get the timezone abbreviation
      const timeZoneAbbr = this.getTimeZoneAbbr(date)

      return `${dateString}`
    },
    getTimeZoneAbbr(date) {
      const dateString = date.toString()
      const timeZoneAbbr = dateString.match(/\(([A-Za-z\s]+)\)/)
      return timeZoneAbbr ? timeZoneAbbr[1] : ''
    }
  }
}
</script>
